// /var/www/website2023/afraapache.com/react-app/src/style/MenuSidebar.scss

.card-MenuComponent {
    .p-menu {
        padding: 0.5rem 0;
        background: #ffffff;
        color: #4b5563;
        border: 0px solid #e5e7eb;
        border-radius: 6px;
        width: auto;      
        
    }

    a {
        text-decoration: none;
    }

    .custom-menu-MenuComponent {

        .p-menuitem-link {
            color: #393939; // White text color for menu items

            &:hover,
            &:focus {
                background-color: #000000; // Slightly lighter shade of black for hover/focus
                color: #d0d0d0 !important;
            }

            .p-menuitem-content>a>.p-menuitem-text{
                color: #ffffff;
            }
            
        }

        .p-menuitem-text {
            &:hover,
            &:focus {
                color: #ffffff !important;
            }            
        }  
        
    }

    .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
        color: unset;
    }

}

    

  
