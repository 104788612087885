.timeline-container {
    .p-timeline {
      background-color: #040404; // Dark mode background color for the timeline
      border-radius: 0.25rem; // Adjust border radius as needed
    }
  
    .p-timeline-event-content {
      color: #fff; // Text color for dark mode
    }
  
    .dark-card {
      background-color: #16151b; // Dark mode background color for the card
      color: #fff; // Text color for dark mode
    }
  
    .custom-marker {
        background-color: #313131 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      box-shadow: 0px 0px 20px rgb(2 60 0);
    }
  }

  
  