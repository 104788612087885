// General styles
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  color: #fff;
  background-color: #333;
}

.App {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  width: 250px; // Width for the sidebar
  background: #222; // Dark background for the sidebar
  padding: 20px;

  ol, ul {
    padding-left: 0rem;
  }

}






.content {
  flex-grow: 1;
  padding: 20px;
  background: #070707; // Slightly lighter background for content
}

.color-index {
  color: #fff;
}

.mobile-sidebar-toggle {
  position: fixed;
  bottom: 0px;
  left: 50%;
  z-index: 1000;

  transform: translateX(-50%);
  background-color: #1e1e1e;
  color: #fff;
  border: none;

  @media (max-width: 768px) {
      display: block; // Only show on mobile
  }

  &:hover {
    background-color: #555;
    color: #ddd;
  }

}


