// /var/www/website2023/afraapache.com/react-app/src/style/page.scss

.card-page1 {
    background-color: #333;
    color: white;
    padding: 20px;
    margin-top: 20px;
  
    .p-card-title {
      color: #ccc;
    }
  
    .p-card-content {
      font-size: 1rem;
      line-height: 1.5;
      color: #eee;
    }
  }
  