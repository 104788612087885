.mySwiper-Contact {
    .swiper-wrapper {
        width: 0px;
        background-color: #333; // Dark background for the entire swiper container
        color: #fff; // Light text color for contrast

        .swiper-slide {
            background-color: #444; // Slightly lighter background for slides to distinguish from the overall background
            border-radius: 8px; // Optional: add border-radius for aesthetic
        }



    }
}


.dark-card-Contact {
    background-color: #131313;
    color: #fff;
    box-shadow: 0px 0px 5px rgb(51 51 51 / 48%);
    height: 300px;
    animation: flicker 5s;
    overflow: overlay;

    @media (max-width: 768px) {
        .p-card-title {
            font-size: 1.0rem;
          
        }

      
    }
}

.dark-bg-1 {
    height: 400px;
    background-image: linear-gradient(#31313180, #0f0f0f80),
        url("https://afaa.website/s/277040.webp");
}

.dark-bg-2 {
    height: 350px;
    position: relative;
    background-image: linear-gradient(#00292c80, #0b0b0b80);
    overflow: hidden;
    opacity: 0.2;

    .image-layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: bottom;
        opacity: 0;
        width: 100%;
        height: 100%;
    }

    .image-first {
        background-image: url("https://afaa.website/s/53f1c3.png");
        animation: fadeInOut 24s linear 12s infinite;
        z-index: 3;
    }

    .image-second {
        background-image: url("https://afaa.website/s/41e7ac.png");
        animation: fadeInOut 30s linear 2s infinite;
        z-index: 4;
    }

    .image-third {
        background-image: url("https://afaa.website/s/b11f63.png");
        animation: fadeInOut 36s linear 6s infinite;
        z-index: 1;
    }

    .image-fourth {
        background-image: url("https://afaa.website/s/05a596.png");
        animation: fadeInOut 24s linear 18s infinite;
        z-index: 2;
    }
}

@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    12.5%,
    87.5% {
        opacity: 1;
    }
}