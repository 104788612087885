// /var/www/website2023/afraapache.com/react-app/src/style/SpeedDialStyles.scss

.speed-index{
    background: #2a2a2a;
    border: 1px solid #575757;
    z-index: 2;
}

.speed-dial-custom {

    a {
        color: rgb(213 213 213);
        text-decoration: none;
        background: #474747;
    }

    .p-speeddial-item {
        padding: 8px;
        cursor: pointer;
    }


    .p-speeddial-button {
        background: #2a2a2a;
        border-color: #3e3e3e;
        
    }



    .p-speeddial-action {    
        &:hover {
            background: #ffffff;
            text-decoration: none;
            color: #000000;
        }


    }



    .p-button {
        background: #2a2a2a;
        border: 1px solid #3e3e3e;
        box-shadow: 0 0 0 2px #303030, 0 0 0 4px #575757, 0 1px 2px 0 rgb(78 78 78);

        &:active {
            border: 1px solid #3e3e3e;
        }

        &:hover {
            border: 1px solid #3e3e3e;
            color: #838383;
        }

        &:focus {
            border: 1px solid #3e3e3e;
        }
    }
}


.custom-sidebar {
    background-color: #2a2a2a; // Dark background for the sidebar
  
    .p-sidebar {
      background-color: #2a2a2a; // Ensuring internal elements also get the same background
    }
  
    h6 {
      color: #c5c5c5; // Light gray color for the "Thank You For Reaching Out" text
    }
  }
  