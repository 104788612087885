.vision-card {
    background: black; // Main background of the card
    color: #868686;
    width: 90%;
    margin: 2em auto;
    padding: 15px;
    font-size: 20px;
    position: relative;
    overflow: hidden; // Ensure everything stays within the card
    z-index: 1; // Higher z-index to ensure text appears on top
   

    p, h5 {
        line-height: 1.5;
        position: relative;
        z-index: 2; // Ensuring text is above the background image
        animation: flicker 4s 
    }

    &::before { // Adding shadow as a separate layer
        content: "";
        position: absolute;
        top: 30%;
        right: 0;
        bottom: 0;
        width: 46%;
        box-shadow: -2px -4px 12px 0px rgb(16 16 16 / 0%);
        z-index: 1;
        background: rgb(0 0 0 / 86%);
        animation: flicker 5s;
    }

    &::after { // Background image adjusted
        content: "";
        position: absolute;
        top: 30%;
        right: 0;
        bottom: 0;
        width: 46%;
        background: url('https://afaa.website/s/563dfa.svg') no-repeat right top / cover;
        filter: blur(0px); // Clear image
        z-index: 0; // Ensure it's behind the shadow
        animation: flicker 5s infinite alternate;
    }

    @keyframes flicker {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}



.vision-card-2 {
    background: black;// Main background of the card
    color: #fff3ea;
    width: 90%;
    margin: 2em auto;
    padding: 15px;
    font-size: 20px;
    position: relative;
    overflow: hidden; // Ensure everything stays within the card
    z-index: 1; // Higher z-index to ensure text appears on top
   

    p, h5 {
        line-height: 1.5;
        position: relative;
        z-index: 2; // Ensuring text is above the background image
        animation: flicker 4    s 
    }

   

    @keyframes flicker {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
