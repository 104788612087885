.p-sidebar-index1 {

    background: #191919;



    .p-sidebar-content {
        // Custom scrollbar styles for dark mode
        &::-webkit-scrollbar {
            width: 8px; // Adjust width of the scrollbar
        }

        &::-webkit-scrollbar-track {
            background: #2a2a2a; // Dark track as background
        }

        &::-webkit-scrollbar-thumb {
            background-color: #6b6b6b; // Grey scrollbar handle
            border-radius: 10px; // Rounded corners of the scrollbar handle
            border: 2px solid #2a2a2a; // Matching border to the track
        }
    }

    .p-link:focus-visible{
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.1rem #6f6f6f !important;
        background: #22272c;
    }

}